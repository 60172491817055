import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Logo from '../components/Header/logo'
import ScrollToTop from 'react-scroll-up'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {portfolio, client_string, date_string, category_string, website_string, single_text, our_services, contact_us} from '../components/strings'
import AOS from 'aos';
import Helmet from 'react-helmet'

var slugify = require('slugify')
class PortfolioPost extends React.Component{
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  
render() {
    const { markdownRemark: post } = this.props.data
        const { title, client, date, category, description, website, website_url, image, meta } = post.frontmatter
        const {next,previous} = this.props.pageContext

     

        var link_category = "portfolio/" + slugify(category, {
          replacement: '-',  
          remove: null,    
          lower: true  
        })
      
  return (
    <Layout>
      <Helmet
          title={"WELL - PORTFOLIO | "+title}
          meta={[
            { name: 'description', content: 'In our portfolio you will find many demanding websites, online stores & applications, but also digital marketing and graphic design projects.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba"+ image },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba"+ image },
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>
      <div data-aos='fade-zoom-in'>
    <div id="s_header" className="section single-mobile-header is-fixed is-full-width is-padding-0">
         <ScrollToTop showUnder={10}
                 style={{
                     zIndex: 99,
                     top:0,
                     left:0,
                     height:'100px',
                     background: 'transparent',
                     cursor: 'default'
                 }}>
         <div className="header-back">
         
         </div>
         </ScrollToTop>
     
     <div className="container main-header is-flex">
         <div className="logo is-left">
             <Link to="/"><Logo/></Link>
         </div>
 
         <div className="navbar is-left">
             
         </div>
       
         <Link to={"/" + link_category} className="contact-close is-right"><FontAwesomeIcon icon={['fas', 'times-circle']} aria-hidden="true" /> {portfolio}</Link>
     </div>
     </div>

      <section id="p_single" className="hero is-fullheight pages">
        <div className="hero-head"></div>
            <div className="hero-body is-padding-0">
                <div className="overlay_image"></div>
                <div className="overlay_color"></div>
                <div className="container">        
                    <div className="columns">
                        <div className="column is-6 image">
                            <img src={image} alt={meta} title={meta}/>
                          
                        </div>
    
                        <div className="column is-6 content">
                            <h1 className="title">{title}</h1>
                            <h4 className="line client"><span>{client_string}:</span>{client}</h4>
                            <h4 className="line date"><span>{date_string}:</span>{date}</h4>
                            <h4 className="line"><span>{category_string}:</span>{category}</h4>
                            {category === "Video Advertisment" && (
                               <h4 className="line"><span>Watch video:</span><a href={website_url} target="_blank">{website}</a></h4>
                            )}
                            {category !== "Video Advertisment" && (
                              <h4 className="line"><span>{website_string}</span><a href={website_url} target="_blank">{website}</a></h4>
                            )}
                            <div className="desc"><span>{our_services}:</span><p>{description}</p></div>
                            
                            <div className="contact-info">
                                <p className="line">{single_text}</p>
                                <Link to="/contact" className="button gold">{contact_us}<span className="icon contact"></span></Link>
                            </div>
                        </div>
                    </div>
                    <ul className="pagination">
                    {previous && (
                      <li>
                        <Link to={previous.fields.slug} rel="prev">
                          ← Previous project
                        </Link>
                      </li>
                    )}

                    {next && (
                      <li>
                        <Link to={next.fields.slug} rel="next">
                          Next project →
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
            </div>
        <div className="hero-foot">
        </div>
    </section>

    </div>
    </Layout>
  )
}
}

PortfolioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PortfolioPost

export const pageQuery = graphql`
  query PortfolioPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        client
        website
        website_url
        category
        image
        description
        meta
      }
    }
  }
`
